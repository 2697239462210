import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { SxProps, Theme } from '@mui/material/styles';
import SearchInput from './SearchInput';
import { FilterWithDependencies } from '../../filtersManifest';

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'grey.50',
  px: 2,
  py: 1,
  my: 2,
  borderRadius: 1,
  justifyContent: 'space-between',
};

export interface Props {
  availableFilters: FilterWithDependencies[];
  disableSearch?: boolean;
}

const Toolbar = ({
  availableFilters,
  disableSearch = false,
}: Props): JSX.Element => (
  <GridToolbarContainer sx={containerStyle}>
    {
      availableFilters.length > 0 ? <GridToolbarFilterButton /> : <div /> // We still need to render something here to keep the layout consistent
    }
    {!disableSearch && (
      <SearchInput useCustomFilterModel={availableFilters.length > 0} />
    )}
  </GridToolbarContainer>
);

export default Toolbar;
