import { createColumnDependencies } from 'components/ModelList/columns';
import DateRangePicker from './common/DateRangePicker';

export const dependencies = createColumnDependencies((columnIds) => [
  columnIds.RUN_START_TIME,
]);

const DateRangePickerStartTime = () => {
  const [RUN_START_TIME] = dependencies;
  return <DateRangePicker columnId={RUN_START_TIME} />;
};

export default DateRangePickerStartTime;
