import { useEffect, useMemo } from 'react';
import { createColumnDependencies } from 'components/ModelList/columns';
import {
  FilterIds,
  FilterOperators,
} from 'components/ModelList/components/FilterPanel';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { useURLSearchParams } from 'hooks/useURLSearchParams';

const dependencies = createColumnDependencies((columnIds) => [columnIds.NAME]);

export interface ModelListFilters {
  filters: GridFilterItem[];
  updateFilterModelItems: (
    newItems: Partial<Record<FilterIds, string>>
  ) => void;
}

/**
 * Hook to manage the filters for the model list.
 * @param dateColumnId A string containing the date column id to filter (only needed when the consuming component filters by date).
 * @returns An object containing the filters and a function to update them.
 */
export const useURLFilters = (dateColumnId?: string) => {
  const [NAME] = dependencies;
  const [queryParams, setQueryParams] = useURLSearchParams();
  const filterText = queryParams[FilterIds.NAME];
  const startDate = queryParams[FilterIds.START_DATE];
  const endDate = queryParams[FilterIds.END_DATE];
  const filteredDateColumnId = queryParams.dateColumnId;

  useEffect(() => {
    if (dateColumnId && dateColumnId !== filteredDateColumnId) {
      setQueryParams({
        dateColumnId,
      });
    }
  }, [filteredDateColumnId, dateColumnId, setQueryParams]);

  const updateFilterModelItems = (
    newQueryParams: Partial<Record<FilterIds, string | null>>
  ) => {
    setQueryParams(newQueryParams);
  };

  const filters: GridFilterItem[] = useMemo(() => {
    let items: GridFilterItem[] = [];

    if (filterText) {
      items.push({
        columnField: NAME,
        id: FilterIds.NAME,
        operatorValue: FilterOperators.CONTAINS,
        value: filterText,
      });
    }

    if (filteredDateColumnId) {
      if (startDate) {
        items.push({
          columnField: filteredDateColumnId,
          id: FilterIds.START_DATE,
          operatorValue: FilterOperators.ON_OR_AFTER,
          value: startDate,
        });
      }

      if (endDate) {
        items.push({
          columnField: filteredDateColumnId,
          id: FilterIds.END_DATE,
          operatorValue: FilterOperators.ON_OR_BEFORE,
          value: endDate,
        });
      }
    }

    return items;
  }, [NAME, filteredDateColumnId, endDate, filterText, startDate]);

  return { filters, updateFilterModelItems };
};
