import { createColumnDependencies } from 'components/ModelList/columns';
import DateRangePicker from './common/DateRangePicker';

export const dependencies = createColumnDependencies((columnIds) => [
  columnIds.DATE_ACCESSED,
]);

const DateRangePickerAccessed = () => {
  const [DATE_ACCESSED] = dependencies;
  return <DateRangePicker columnId={DATE_ACCESSED} />;
};

export default DateRangePickerAccessed;
