import dayjs from 'dayjs';
import {
  Callback,
  Column,
  ColumnGetter,
  MenuColumnGetter,
  ResourceCreator,
  Row,
  ValidColumnIds, // eslint-disable-line no-restricted-imports
} from './types';
import { ContextMenu } from 'components/ContextMenu';
import { ResourceActions } from 'components/ResourceActions';
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';

/**
 * This allows access to column IDs.
 * It should only be used as a fallback when create[Rows|Columns] functions are insufficient.
 * Do not use this to access column IDs in column filters -
 * prefer the dependency pattern using createColumnDependencies.
 */
export const _accessColumnIds = <T extends any>(cb: Callback<T>): T =>
  cb(ValidColumnIds);

export const createColumnDependencies: ResourceCreator<ValidColumnIds[]> = (
  cb
) => cb(ValidColumnIds);

export const createColumns = <RowType extends object = any>(
  cb: Callback<Column<RowType>[]>
): Column<RowType>[] => cb(ValidColumnIds);

export const createRows = <RowType extends object = any>(
  cb: Callback<Row<RowType>[]>
): Row<RowType>[] => cb(ValidColumnIds);

export const getNameColumn: ColumnGetter = (overrides) => ({
  field: ValidColumnIds.NAME,
  headerAlign: 'left',
  flex: 1,
  minWidth: 200,
  ...overrides,
});

export const dateValueFormatter = ({ value }: GridValueFormatterParams) =>
  value ? dayjs(value).format('ddd, Do MMM, YYYY (HH:mm)') : '';

export const getDateColumn: ColumnGetter<'field' | 'headerName'> = (
  overrides
) => ({
  type: 'dateTime',
  headerClassName: 'hideRightSeparator',
  valueFormatter: dateValueFormatter,
  headerAlign: 'left',
  minWidth: 225,
  ...overrides,
});

export const renderMenuCell = (
  { value }: GridRenderCellParams,
  contextMenuProps: Parameters<MenuColumnGetter>[1]
) => {
  const defaultMenuOptions = [
    ResourceActions.RENAME,
    ResourceActions.DUPLICATE,
    ResourceActions.DELETE,
  ];
  return (
    value && (
      <ContextMenu
        menuOptions={defaultMenuOptions}
        resourceId={value}
        {...contextMenuProps}
      />
    )
  );
};

export const getMenuColumn: MenuColumnGetter<'field'> = (
  overrides,
  contextMenuProps
) => ({
  headerName: '',
  headerClassName: 'hideRightSeparator',
  filterable: false,
  sortable: false,
  renderCell: (renderCellProps) =>
    renderMenuCell(renderCellProps, contextMenuProps),
  width: 50,
  ...overrides,
});

export const emailColumn: Column = {
  field: ValidColumnIds.EMAIL,
  headerName: 'Email',
  headerAlign: 'left',
  flex: 1,
};

export const dateCreatedColumn: Column = getDateColumn({
  field: ValidColumnIds.DATE_CREATED,
  headerName: 'Created At',
});

export const dateModifiedColumn: Column = getDateColumn({
  field: ValidColumnIds.DATE_MODIFIED,
  headerName: 'Last modified',
});

export const dateAccessedColumn: Column = getDateColumn({
  field: ValidColumnIds.DATE_ACCESSED,
  headerName: 'Last accessed',
});
