import { createColumnDependencies } from 'components/ModelList/columns';
import DateRangePicker from './common/DateRangePicker';

export const dependencies = createColumnDependencies((columnIds) => [
  columnIds.DATE_MODIFIED,
]);

const DateRangePickerModified = () => {
  const [DATE_MODIFIED] = dependencies;
  return <DateRangePicker columnId={DATE_MODIFIED} />;
};

export default DateRangePickerModified;
