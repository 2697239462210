export enum ResourceActions {
  CREATE = 'Create',
  RENAME = 'Rename',
  DUPLICATE = 'Duplicate',
  DELETE = 'Delete',
  EDIT = 'Edit',
  START = 'Start',
  PAUSE = 'Pause',
  RESUME = 'Resume',
  STOP = 'Stop',
  RESET = 'Reset',
  LOAD = 'Load',
  CONFIGURE = 'Configure',
}

const stringToResourceActions: Record<string, ResourceActions> = {
  create: ResourceActions.CREATE,
  rename: ResourceActions.RENAME,
  duplicate: ResourceActions.DUPLICATE,
  delete: ResourceActions.DELETE,
  edit: ResourceActions.EDIT,
  start: ResourceActions.START,
  pause: ResourceActions.PAUSE,
  resume: ResourceActions.RESUME,
  stop: ResourceActions.STOP,
  reset: ResourceActions.RESET,
  load: ResourceActions.LOAD,
  configure: ResourceActions.CONFIGURE,
};

export const lookupResourceActions = (
  resourceAction: string
): ResourceActions | undefined => {
  return stringToResourceActions[resourceAction.toLowerCase()];
};
