import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import { createColumnDependencies } from 'components/ModelList/columns';
import { FilterIds } from '../..';
import { useURLFilters } from 'hooks/useURLFilters';
import { useURLSearchParams } from 'hooks/useURLSearchParams';

function getISOString(
  input: Dayjs | null,
  type: 'start' | 'end'
): string | null {
  if (!input) return null;
  if (type === 'start') return input.startOf('day').toISOString();
  return input.endOf('day').toISOString();
}

function getNormalizedValues(
  val: DateRange<Dayjs>
): [Dayjs | null, Dayjs | null] {
  const [startValue, endValue] = val;
  if (startValue && endValue) return [startValue, endValue];
  if (!endValue && startValue) return [startValue, startValue];
  if (!startValue && endValue) return [endValue, endValue];
  return [null, null];
}

interface Props {
  columnId: ReturnType<typeof createColumnDependencies>[number];
}

const DateRangePicker = ({ columnId }: Props) => {
  const theme = useTheme();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { updateFilterModelItems } = useURLFilters(columnId);
  const [searchParams] = useURLSearchParams();
  const datePickerStartValue = useMemo(
    () => searchParams[FilterIds.START_DATE] ?? null,
    [searchParams]
  );
  const datePickerEndValue = useMemo(
    () => searchParams[FilterIds.END_DATE] ?? null,
    [searchParams]
  );

  const handleDatePickerChange = useCallback(
    (value: DateRange<Dayjs>): void => {
      const [startValue, endValue] = getNormalizedValues(value);
      updateFilterModelItems({
        [FilterIds.START_DATE]: getISOString(startValue, 'start'),
        [FilterIds.END_DATE]: getISOString(endValue, 'end'),
      });
    },
    [updateFilterModelItems]
  );

  const handleClearClick = useCallback(() => {
    updateFilterModelItems({
      [FilterIds.START_DATE]: null,
      [FilterIds.END_DATE]: null,
    });
  }, [updateFilterModelItems]);

  return (
    <Box position="relative">
      <StaticDateRangePicker
        displayStaticWrapperAs={isLargerScreen ? 'desktop' : 'mobile'}
        onChange={handleDatePickerChange}
        value={[datePickerStartValue, datePickerEndValue]}
        renderInput={() => <></>}
        showToolbar={false}
        disableFuture
      />
      <Box
        position="absolute"
        bottom={10}
        right={isLargerScreen ? 10 : undefined}
        left={!isLargerScreen ? 10 : undefined}
      >
        <Button onClick={handleClearClick} size="small">
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default DateRangePicker;
