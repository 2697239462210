import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { normalizeRouterQuery } from '@automata/utils';

/**
 * Returns a stateful object that represents the current search params and a function to update them.
 * @returns A tuple [searchParams, updateFunction]
 */
export const useURLSearchParams = (): [
  Record<string, string>,
  (value: Record<string, string | boolean | null>) => void
] => {
  const router = useRouter();
  const { query } = router;
  const [searchParams, setSearchParams] = useState(normalizeRouterQuery(query));

  useEffect(() => {
    setSearchParams(normalizeRouterQuery(query));
  }, [query]);

  const updateValue = (newParams: Record<string, string | boolean | null>) => {
    const query = { ...router.query };

    Object.entries(newParams).forEach(([key, value]) => {
      if (value === null || value === '') {
        delete query[key];
      } else {
        query[key] = `${value}`;
      }
    });

    router.replace({ query });
  };

  return [searchParams, updateValue];
};
