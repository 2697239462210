import { ReactElement, ReactNode } from 'react';
import Image from 'next/image';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  children?: ReactNode;
  alt?: string;
  img?: string;
}

const styles: { [key: string]: SxProps<Theme> } = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    width: '845px',
    minHeight: '300px',
    margin: '0 auto',
    padding: ['10px', '80px 10px 10px 100px'],
  },
};

const EmptyPlaceholder = ({
  children,
  alt = 'no items to display',
  img = '/empty-page-illustration.svg',
}: Props): ReactElement => {
  return (
    <Box sx={styles.wrapper}>
      <Image src={img} alt={alt} fill />
      {children && <div>{children}</div>}
    </Box>
  );
};

export default EmptyPlaceholder;
