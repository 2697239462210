import { createColumnDependencies } from 'components/ModelList/columns';
import DateRangePicker from './common/DateRangePicker';

export const dependencies = createColumnDependencies((columnIds) => [
  columnIds.DATE_CREATED,
]);

const DateRangePickerCreated = () => {
  const [DATE_CREATED] = dependencies;
  return <DateRangePicker columnId={DATE_CREATED} />;
};

export default DateRangePickerCreated;
