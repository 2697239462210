import { _accessColumnIds, createColumnDependencies } from './columns';
import filtersManifest from './filtersManifest';
import { Column, Row } from './types';
import { GridSortModel } from '@mui/x-data-grid-premium';

export function getAvailableFilters(
  columns: Column[],
  filtersManifestDep = filtersManifest
) {
  const availableColumnFields = columns.map(({ field }) => field);
  const availableFilters = filtersManifestDep.filter(({ dependencies }) =>
    dependencies.every((columnId) => availableColumnFields.includes(columnId))
  );
  return availableFilters;
}

export function getSortModel(columns: Column[]): GridSortModel | undefined {
  const availableColumnFields = columns.map(({ field }) => field);
  const columnIdOrderOfPreference = createColumnDependencies((columnIds) => [
    columnIds.DATE_MODIFIED,
    columnIds.DATE_CREATED,
    columnIds.DATE_ACCESSED,
    columnIds.NAME,
  ]);
  const [columnFilterId] = availableColumnFields
    .filter((columnId) => columnIdOrderOfPreference.includes(columnId))
    .sort(
      (a, b) =>
        columnIdOrderOfPreference.indexOf(a) -
        columnIdOrderOfPreference.indexOf(b)
    );

  if (!columnFilterId) return undefined;

  return [
    {
      field: columnFilterId,
      sort: _accessColumnIds((validColumnIds) =>
        columnFilterId === validColumnIds.NAME ? 'asc' : 'desc'
      ),
    },
  ];
}

export function verifyRows(rows: Row[]): void | never {
  _accessColumnIds((columnIds) => {
    rows.forEach((row) => {
      Object.keys(row).forEach((columnId) => {
        if (!Object.values(columnIds).includes(columnId as any)) {
          throw new Error(
            `Row contains invalid columnId: ${columnId}. Valid columnIds: ${Object.values(
              columnIds
            ).join(', ')}`
          );
        }
      });
    });
  });
}
