import DateRangePickerModified, {
  dependencies as dateRangePickerModifiedDependencies,
} from './components/FilterPanel/filters/DateRangePickerModified';
import DateRangePickerAccessed, {
  dependencies as dateRangePickerAccessedDependencies,
} from './components/FilterPanel/filters/DateRangePickerAccessed';
import DateRangePickerCreated, {
  dependencies as dateRangePickerCreatedDependencies,
} from './components/FilterPanel/filters/DateRangePickerCreated';
import DateRangePickerStartTime, {
  dependencies as dateRangePickerStartTimeDependencies,
} from './components/FilterPanel/filters/DateRangePickerStartTime';
import { ComponentType } from 'react';
import { createColumnDependencies } from './columns';

export interface FilterWithDependencies {
  key: string;
  component: ComponentType;
  dependencies: ReturnType<typeof createColumnDependencies>;
}

const filtersManifest: Array<FilterWithDependencies> = [
  {
    key: 'dateRangePickerModified',
    component: DateRangePickerModified,
    dependencies: dateRangePickerModifiedDependencies,
  },
  {
    key: 'dateRangePickerAccessed',
    component: DateRangePickerAccessed,
    dependencies: dateRangePickerAccessedDependencies,
  },
  {
    key: 'dateRangePickerCreated',
    component: DateRangePickerCreated,
    dependencies: dateRangePickerCreatedDependencies,
  },
  {
    key: 'dateRangePickerStartTime',
    component: DateRangePickerStartTime,
    dependencies: dateRangePickerStartTimeDependencies,
  },
];

export default filtersManifest;
