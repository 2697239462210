import { useCallback, useState } from 'react';
import {
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-premium';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { debounce } from 'lodash';
import { FilterIds } from '../FilterPanel';
import { useURLFilters } from 'hooks/useURLFilters';

interface Props {
  useCustomFilterModel: boolean;
}

const SearchInput = ({ useCustomFilterModel }: Props) => {
  const [searchParams] = useURLSearchParams();
  const { updateFilterModelItems } = useURLFilters();
  const [quickFilterText, setQuickFilterText] = useState(
    searchParams[FilterIds.NAME]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetParams = useCallback(
    debounce((value: string) => {
      updateFilterModelItems({ [FilterIds.NAME]: value });
    }, 500),
    []
  );

  const handleQuickFilterChange = (value: string) => {
    setQuickFilterText(value);

    debouncedSetParams(value);
  };

  const controlledInputProps: Partial<GridToolbarQuickFilterProps> = {
    onChange: (event) => {
      handleQuickFilterChange(event.target.value);
    },
    value: quickFilterText,
  };

  return (
    <GridToolbarQuickFilter
      variant="outlined"
      size="small"
      sx={{ pb: 0 }}
      {...(useCustomFilterModel ? controlledInputProps : {})}
    />
  );
};

export default SearchInput;
